html {
  background-color: black;
}

body { 
  background: url('../images/solgardArt.jpg') no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.ui.segment {
  background: black;
}

.footer {
  position: absolute;
  bottom: 0%;
  width: 100%;
  height: 2vh;
  padding-top: 5px;
}

